// Import our custom CSS
import "../scss/styles.scss";

// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";

// const opening = require("./opening");
// opening.openingAnimation();

const InView = require("./InView");
InView.InView();

const works = require("./works");
works.worksVue();
