import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export function InView() {
  const trigger = document.querySelectorAll("[data-trigger]");
  trigger.forEach(function (userItem) {
    ScrollTrigger.create({
      trigger: userItem,
      start: "top bottom-=50px",
      end: "bottom top+=50px",
      toggleClass: { targets: userItem, className: "InView" },
      once: true,
    });
  });
}
