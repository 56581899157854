import Vue from "vue";
import { gsap } from "gsap";
const InView = require("./InView");
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import worksJson from "./works.json";
export function worksVue() {
  const works = new Vue({
    el: "#works",
    data: {
      isShowDetail: false,
      movieMode: 0,
      works: worksJson,
      worksDetail: {},
    },
    watch: {
      isShowDetail(value) {
        if (value) {
          document.querySelector("body").classList.add("modal-mode");
          this.$nextTick(function () {
            this.scrollPlayMovie();
          });
        } else {
          document.querySelector("body").classList.remove("modal-mode");
        }
      },
    },
    mounted() {
      InView.InView();
    },
    methods: {
      worksOpen(i) {
        this.isShowDetail = true;
        this.worksDetail = this.works.find((item) => item.id === i);
      },
      scrollPlayMovie() {
        ScrollTrigger.create({
          scroller: "#modalDetail",
          trigger: "#movieArea",
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          once: false,
          onEnter: function () {
            console.log("onEnter");
            works.movieMode = 1;
          },
        });
      },
    },
  });
}
